function throttled(timeout, fn) {
    let handle = null, value = null

    return function (...args) {
        handle ||= setTimeout(function() {
            handle = null
            value = fn(...args)
        }, timeout)

        return value
    }
}

class Application {
    #FPS = 1000 / 60

    #mode = ''
    #header = document.querySelector('header')
    #nav = this.#header.querySelector('nav')

    attach() {
        const onResize = 
            throttled(
                this.#FPS, 
                ({ target: { innerWidth } }) => this.#windowResized(innerWidth)
            )
        window.addEventListener('resize', onResize)

        this.#header.querySelector('button[data-action=showMenu]')
            .addEventListener('click', () => this.#showMenu())

        this.#header.querySelector('button[data-action=hideMenu]')
            .addEventListener('click', () => this.#hideMenu())

        this.#nav.querySelectorAll('a')
            .forEach(e => e.addEventListener('click', () => {
                if(this.#mode === 'mobile') {
                    this.#hideMenu()
                }
            }))

        this.#windowResized(window.innerWidth)
    }

    #windowResized(width) {
        if(width < 1024) {
            this.#mobileMode()
        } else {
            this.#desktopMode()
        }
    }

    #showMenu() {
        this.#nav.setAttribute('data-open', '')
    }

    #hideMenu() {
        this.#nav.removeAttribute('data-open')
    }

    #mobileMode() {
        this.#mode = 'mobile'
        this.#header.classList.replace('desktop', 'mobile') ||
            this.#header.classList.add('mobile')
    }

    #desktopMode() {
        this.#mode = 'desktop'
        this.#header.classList.replace('mobile', 'desktop') ||
            this.#header.classList.add('desktop')
    }
}

document.addEventListener('DOMContentLoaded', function() {
    const app = new Application
    app.attach()
})